const globalTranslations = {
  WELCOME: ['Bienvenido', 'Welcome'],
  EMAIL: ['Correo Electrónico', 'Email'],
  PASSWORD: ['Contraseña', 'Password'],
  FIRST_NAME: ['Nombre', 'Name'],
  LAST_NAME: ['Apellido', 'Last name'],
  REPEATE_PASSWORD: ['Repetir Contraseña', 'Repeat password '],
  LOGIN: ['Ingresar', 'Login'],
  LOGOUT: ['Cerrar Sesión', 'Logout'],
  SEND: ['Enviar', 'Send'],
  FORGOT_PASSWORD: ['¿Olvidaste tu contraseña?', 'Did you forget your password?'],
  CHANGE_PASSWORD: ['Cambiar contraseña', 'Change password'],
  RECOVER_PASSWORD: ['Recuperar contraseña', 'Recover password'],
  TASKS: ['Tareas', 'Tasks'],
  COURSES: ['Cursos', 'Courses'],
  RESUME: ['Curriculum Vitae', 'Resume'],
  SIGN_UP: ['Registrarme', 'Sign Up'],
  ERR_DONT_HAVE_ACCOUNT: ['¿No tienes cuenta? Registrate', 'You do not have an account? Sign up'],
  ERR_USER_NOT_FOUND: ['El correo ingresado es inválido', 'The email entered is invalid'],
  ERR_NOT_ACTIVE: ['El correo ingresado no fue activado, por favor ingrese a su correo electrónico y active su cuenta', 'The email entered was not activated, please log in to your email and activate your account'],
  ERR_PASSWORD_NOT_VALID: ['La contraseña debe contener mayusculas, minusculas, números y entre 8 y 20 caracteres', 'The password must contain uppercase, lowercase, numbers and between 8 and 20 characters'],
  ERR_TOKEN_EXPIRED: ['El enlace ha expirado', 'The link has expired'],
  ERR_UNEXPECTED: ['Error inesperado', 'Unexpected error'],
  ERR_USER_INVALID: ['El enlace ingresado no es válido', 'The link entered is not valid'],
  ERR_PASSWORD_DONT_MATCH: ['Las contraseñas no coinciden', 'The passwords do not match'],
  ERR_INVALID_CREDENTIALS: ['El usuario y/o la contraseña ingresados son incorrectos, por favor intente nuevamente.', 'The username or password you entered is incorrect, please try again.'],
  OR: ['ó', 'or'],
};

export default globalTranslations;
