import styled from 'styled-components/macro';

const Loading = (props) => <ImgLoading src="/images/loading.gif" {...props} />;

const ImgLoading = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height: 100%;
`;

export default Loading;