import NotificationManager from '../../helpers/notifications/notifications';

import history from '../../helpers/history';
import store from '../store';

export const URI_ABM_VALUES = '/abm/values/';
export const URI_ABM_SCHEMA = '/abm/schema/';

const URI_LOGIN = '/user/login/';
const URI_REGISTER = '/user/register/';
const URI_ABM = '/abm/';
const URI_RECOVERACCOUNT = '/user/recoveryaccount/';
const URI_BASE = process.env.NODE_ENV === 'production' ? `${window.location.origin}/api` : 'http://localhost:8888/api';

export let Token = localStorage.getItem('token');

export const customFetch = async (method, extension, body) => {
  store.dispatch({ type: 'USER_LOADING' });
  const headers = {
    'content-type': 'application/json',
    Accept: 'application/json',
  };
  if (Token) {
    headers.Authorization = Token;
  }
  const requestOptions = {
    method,
    headers: new Headers(headers),
  };
  if (body) {
    requestOptions.body = JSON.stringify(body);
  }
  const fetchRes = await fetch(URI_BASE + extension, requestOptions);
  return fetchRes.json();
};

export const queryParams = (params) => {
  if (!params) return '';
  return `/?${Object.keys(params)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&')}`;
}

export const login = async (params) => {
  const response = await customFetch('post', URI_LOGIN, params);
  if (response.error) {
    logout();
    store.dispatch({ type: 'ERROR', error: response.error });
    return;
  }
  localStorage.setItem('token', response.token);
  Token = response.token;
  if (response.admin) {
    history.push('/abm/user');
  } else {
    history.push('/abm/timeSheet');
  }
  return response
};

export const recoverAccount = async (params) => {
  await customFetch('post', URI_RECOVERACCOUNT, params);
  store.dispatch({ type: 'SUCCESS' });
  NotificationManager.success('Por favor verifique su correo electrónico para restablecer la contraseña');
  history.push('/');
};

export const validResetPassword = async () => {
  await customFetch('get', `/user${window.location.pathname}`);
};

export const resetPassword = async (params) => {
  const response = await customFetch('post', `/user${window.location.pathname}`, params);
  if (response.error) {
    store.dispatch({ type: 'ERROR', error: response.error });
    history.push('/');
    return;
  }
  localStorage.setItem('token', response.token);
  Token = response.token;
  NotificationManager.success('Contraseña actualizada con éxito');
  history.push('/');
};

export const register = async (params) => {
  const response = await customFetch('post', URI_REGISTER, params);
  store.dispatch({ type: 'SUCCESS' });
  NotificationManager.success('Registro Exitoso, ingrese a su correo para activar la cuenta');
  history.push('/');
  return response;
};

export const saveAbmProduct = async (params) => {
  const response = await customFetch('post', URI_ABM + params.name, params);
  if (response.error) {
    store.dispatch({ type: 'ERROR', error: response.message });
    return;
  }
  store.dispatch({ type: 'SUCCESS' });
};

export const logout = () => {
  localStorage.removeItem('token');
  Token = null;
  history.push('/login');
};
