import { Suspense, lazy } from 'react';
import { BrowserRouter, Router, Switch, Route } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import './helpers/notifications/notifications.css';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import history from './helpers/history';
import PrivateRoute from './pages/routes/PrivateRoute';
import PublicRoute from './pages/routes/PublicRoute';
import Loading from './components/Loading';

const Home = lazy(() => import('./pages/home/Home'));
const Abm = lazy(() => import('./pages/home/Abm'));
const Login = lazy(async () => await import('./pages/login/Login'));
const Register = lazy(() => import('./pages/register/Register'));
const RecoverPassword = lazy(() => import('./pages/recoverPassword/recoverPassword'));
const ResetPassword = lazy(() => import('./pages/resetPassword/resetPassword'));
const HelpComponent = lazy(() => import('./pages/help/help'));

const App = () => {
  console.log('app')
  return (
    <BrowserRouter>
      <NotificationContainer />
      <Router history={history}>
        <Route
          render={({ location }) => (
            <TransitionGroup component={null}>
              <CSSTransition timeout={500} classNames="page" key={location.key}>
                <Suspense fallback={<Loading />}>
                  <Switch>
                    <PublicRoute path="/login" component={Login} exact />
                    <PublicRoute path="/register" component={Register} exact />
                    <PublicRoute path="/recoverpassword" component={RecoverPassword} exact />
                    <PublicRoute path="/resetpassword" component={ResetPassword} />
                    <PrivateRoute path="/abm/:object" component={Abm} />
                    <PrivateRoute path="/help" component={HelpComponent} />
                    <PrivateRoute path="/" component={Home} to="/" />
                  </Switch>
                </Suspense>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </Router>
    </BrowserRouter>
  );
};

export default App;
