import { combineReducers } from 'redux';
import { localizeReducer } from 'react-localize-redux';
import user from './user';

const rootReducer = combineReducers({
  user,
  localize: localizeReducer,
});

export default rootReducer;
