const initialState = {
  isLoading: false,
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case 'USER_LOADING':
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        isLoading: false,
        token: action.response.token,
        user: action.response,
        success: true,
        error: null,
      };
    case 'LOGOUT_SUCCESS':
      return {
        ...state,
        isLoading: false,
        token: null,
        user: null,
      };
    case 'ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.error,
        success: false,
      };
    case 'SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        error: null,
      };
    case 'ABM_CLEAN':
      return {
        ...state,
        abmitems: null,
        isLoading: true,
      };
    case 'ABM_LOADING':
      if (state.abmitems && state.abmitems.name !== action.product) {
        return {
          ...state,
          abmitems: null,
        };
      }
      return {
        ...state,
      };
    default:
      return state;
  }
}
