import React from 'react';
import ReactDOM from 'react-dom';
// import { Provider } from 'react-redux';
// import { LocalizeProvider } from 'react-localize-redux';
// import store from './store/store';
import App from './App';
import TranslationInitializer from './components/TranslationInitializer';
import UserProvider from './context/userContext'
import 'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(
    <UserProvider>
      <TranslationInitializer />
      <App />
    </UserProvider>,
  document.getElementById('root'),
);
