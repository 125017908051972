import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Token } from '../../store/actions/user';

const PrivateRoute = ({ component: Component }) => {
  console.log('privateroute')
  return <Route render={(props) => (Token ? <Component {...props} /> : <Redirect to={{ pathname: '/login' }} />)} />
};

export default PrivateRoute;
