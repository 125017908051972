
import React, { createContext, useEffect, useState } from "react";
import { login, Token } from "../store/actions/user";

export const UserContext = createContext ({
  setUser: () => {},
});

const UserProvider= ({ children }) => {
  const [user, setUser] = useState()

  useEffect(() => {
    const init = async () => {
      if (!Token) return;
      setUser(await login({}))
    }
    init()
  }, [])

  return (
    <UserContext.Provider value={{ setUser, user }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
