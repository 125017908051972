import { createStore } from 'redux';
import rootReducer from './reducers';

const serializedState = localStorage.getItem('token');

let initialState;
if (serializedState) {
  // initialState = JSON.parse(serializedState);
}

const store = createStore(rootReducer, initialState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export default store;
