import { withLocalize } from 'react-localize-redux';
import { renderToStaticMarkup } from 'react-dom/server';
import globalTranslations from '../helpers/translations/globalTranslations';
import languages from '../helpers/translations/languages';
import { useEffect } from 'react';

const TranslationInitializer = ({ initialize }) => {
  useEffect(() => {
    initialize({
      languages,
      translation: globalTranslations,
      options: {
        renderToStaticMarkup,
        renderInnerHtml: true,
        defaultLanguage: 'en',
      },
    });
  }, []);

  return null;
};

export default withLocalize(TranslationInitializer);
